<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-sheet class="mx-auto pa-6" elevation="6" max-width="340">
        <v-row justify="center" class="mb-4">
          <v-img
            src="/img/pd-badge-192x192.png"
            max-width="72"
            max-height="72"
          ></v-img>
        </v-row>
        <h5 class="text-h5 mb-3 text-center font-weight-bold">
          Ridgewolf Login
        </h5>

        <v-row v-if="!authenticated" align="center">
          <v-col cols="12">
            <div id="firebaseui-auth-container" class="mr-2"></div>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <div class="text-center">
              <div class="my-2">
                <v-btn large color="error" @click="logout">Logout</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>

        <div>
          <p class="caption text-center mt-6">By continuing your are indicating that you accept our Terms of Service and Privacy Policy</p>
        </div>

        <div>
          <p class="caption mt-6 text-center grey--text lighten-3">
            Version {{ appVersion }}
          </p>
        </div>
      </v-sheet>
    </v-row>
  </v-container>
</template>


<script>
import { firebase, fetchUserClaims } from './../services/firebase'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
// import { routeNames } from './../router'

export default {
  data() {
    return {
      user: {
        loading: true,
        loggedIn: false,
        data: {}
      }
    }
  },
  mounted() {
    this.renderFirebaseUI()
  },
  computed: {
    authenticated() {
      return this.user.loggedIn
    },
    firstName() {
      if (this.user.data.displayName) {
        return this.user.data.displayName.split(' ')[0]
      }
      return null
    },
    appVersion() {
      return '0.1.0' 
    }
  },
  methods: {
    renderFirebaseUI() {
      var ui = firebaseui.auth.AuthUI.getInstance()

      if (!ui) {
        ui = new firebaseui.auth.AuthUI(firebase.auth())
      }

      let uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: async function (
            authResult,
            redirectUrl
          ) {
            fetchUserClaims()
            return true
          },
          uiShown: function () {
            // The widget is rendered, so hide the loader.
            //this.loading = false
            console.log('FIX LOADING RENDERED IN LOGIN')
          }
        },
        signInFlow: 'popup',
        signInSuccessUrl: "/my/basecamp",
        signInOptions: [
          {
            provider:firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            customParameters: {
              prompt: 'select_account'
            }
          },
          // Twitter does not support scopes
          firebase.auth.TwitterAuthProvider.PROVIDER_ID,
          'apple.com',
          'microsoft.com',
          'yahoo.com'
        ]
      }

      ui.start('#firebaseui-auth-container', uiConfig)
    }
  }
}
</script>

<style>

/* .v-application ul {
  padding-left:0;
} */

ul.firebaseui-idp-list {
  padding-left:0;
}
</style>
